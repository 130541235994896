export const useUpdateServiceWorker = () => {
  const updateServiceWorker = async (): Promise<void> => {
    try {
      navigator.serviceWorker.getRegistration().then(async (registration) => {
        if (registration && registration.waiting) {
          console.log('service worker registration exists');
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          console.log('service worker updated');
          window.location.reload();
        }
      });
    } catch (error) {
      console.log('error while updating service worker', error);
    }
  };

  const hasPendingUpdate = async (): Promise<boolean> => {
    try {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration && registration.waiting) {
        return true;
      }
      return false;
    } catch (error) {
      console.log('error while checking for pending update', error);
      return false;
    }
  };

  return { updateServiceWorker, hasPendingUpdate };
};
