import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { VersionString } from 'modules/main';
import { setAppVersionInfoSeen } from 'modules/metadata';
import { Button, Tooltip } from 'modules/ui/components';
import {
  Footer,
  Content,
  Title,
  InnerContent,
  TitleText,
  TooltipListContainer,
} from './styledComponents';
import { useUpdateServiceWorker } from 'modules/serviceWorker';

const WhatsNewModal: React.FC = () => {
  const dispatch = useDispatch();
  const [hasUpdate, setHasUpdate] = useState(false);
  useEffect(() => {
    dispatch(setAppVersionInfoSeen(VersionString));
  }, [dispatch]);

  const { hasPendingUpdate, updateServiceWorker } = useUpdateServiceWorker();

  useEffect(() => {
    hasPendingUpdate().then((result) => {
      setHasUpdate(result);
    });
  }, [hasPendingUpdate]);
  return (
    <>
      <Content>
        <Title>Version 0.6</Title>
        <InnerContent>
          We've added a more advanced support for{' '}
          <b>inclusion and exclusion zones</b>. You can now create your own sets
          of inclusion zones (areas to keep your routing inside of) and
          exclusion zones (areas to avoid while routing).
          <ul>
            <li>
              Create new inclusion and exclusion zones by drawing directly on
              the map
            </li>
            <li>Edit existing zones by moving points around</li>
            <li>
              Upload GeoJSON files with a single inclusion or multiple exclusion
              zones
            </li>
            <li>Set active or disable each zone as you need them</li>
            <li>
              When creating a new routing, select which inclusion zone to keep
              inside of
            </li>
            <li>
              When creating a new routing, select a new option to avoid your
              created (and active) exclusion zones
            </li>
          </ul>
        </InnerContent>
        <Title>Version 0.5</Title>
        <InnerContent>
          We've added support for <b>creating and managing routes </b>through My
          Routes menu. Creating own routes is useful for storing frequently
          analyzed routes you can quickly perform routings on, including{' '}
          <b>multi-point routes</b>.
          <ul>
            <li>
              Create new routes by clicking points on the map and adjust them by
              using drag & drop
            </li>
            <li>Perform new routings directly on created routes</li>
            <li>Support for multi-point routes (and routings!)</li>
            <li>
              Dozens of small improvements to the user experience, and bug fixes
            </li>
          </ul>
        </InnerContent>
        <Title>Version 0.4</Title>
        <InnerContent>
          This release has been focused on GFS - we’ve added support for
          <b> GFS Ensemble </b>
          routing,
          <b> GFS meteograms </b>
          and
          <b> GFS Wavewatch III </b>
          wave model.
          <br />
          <ul>
            <li>
              With the support for GFS Ensemble (GEFS) routing with 30 ensemble
              members, Dorado now fully supports deterministic and probabilistic
              routing for both GFS and ECMWF
            </li>
            <li>
              Added GFS Wavewatch III wave model products under meteo
              visualizations, enabling analyzing and displaying all relevant
              data for GFS routings, GFS wind and GFS waves
            </li>
            <li>
              Added GFS meteograms, right-click - Download full meteogram now
              downloads both ECMWF and GFS meteograms
            </li>
            <li>
              Switched the format of all coordinates to degrees and decimal
              minutes (from 40° 26′ 46” to 40° 26.767′) following the accepted
              standard in routing apps
            </li>
            <li>
              Support for wind speed limit when creating a new routing, for
              scenarios when you’d want to avoid going through, for example,
              more than 40 kts of wind
            </li>
            <li>
              Several UI fixes, improved meteograms (color coded charts, visual
              mark of the current moment, tabs for ECMWF & GFS meteograms), new
              updated exclusion zones
            </li>
          </ul>
        </InnerContent>
        <Title>Version 0.3</Title>
        <InnerContent>
          Two major features in this update,
          <b> ECMWF/GFS wind comparison </b>
          to analyze areas with different forecasts between the two models, and
          <b> Polar spread</b>
          , that automatically adds routing lines for different polar
          efficiencies. And of course, a bunch of smaller improvements all
          across the board.
          <br />
          <ul>
            <li>
              ECMWF/GFS wind comparison shows a heat map of wind speed
              differences, as well as wind barbs for both models for easier
              analysis of areas where meteo models are different
            </li>
            <li>
              Polar spread adds the routing calculation for 80%, 85%, 90%, 95%,
              100%, 105%, 110%, 115%, and 120% polar efficiencies and displays
              it along with the original request
            </li>
            <li>
              Added this amazing “What’s new” window to help with tracking all
              of the product updates and new features that are continuously
              being added
            </li>
            <li>
              A simple, yet efficient measurement tool to measure distances on
              the map
            </li>
            <li>
              Extended the meteo and routing domain to the Southern Ocean (in
              case you were running into errors trying to do routings in the
              Southern Ocean, it’s fixed now)
            </li>
          </ul>
        </InnerContent>
        <Title>Version 0.2</Title>
        <InnerContent>
          Continuous improvements across the product (20+ fixes and
          improvements), and implementing new features that rely on our server
          cloud-based architecture, most importantly
          <b> ECMWF Ensemble routing</b>
          .
          <br />
          <ul>
            <li>
              Support for ECMWF Ensemble routing with 51 ensemble members, with
              the spread of ensemble routings providing information about the
              uncertainty of the weather forecast and possible routes
            </li>
            <li>
              Sails configuration supported in both the route popup and in the
              routing table
            </li>
            <li>
              Download size estimator for meteo downloads for complete
              transparency into all data transfers
            </li>
            <li>Displaying the Vendée Globe fleet positions on the map</li>
            <li>
              More control over routing requests, including manual input of
              waypoint coordinates and custom start time
            </li>
            <li>
              Improved meteo visualizations supporting overlaying different
              components (e.g. Swell Direction + Wind Waves Direction to analyze
              where to expect breaking waves)
            </li>
            <TooltipListContainer>
              <li>
                Displaying boat location from the Vendée Globe fleet data, enter
                the personalized URL into General - Settings - URL for live boat
                data in the format&nbsp;
                <u>https://gea.arso.gov.si/vg2020-dev/fleet/getLoc?id=X</u>
                ,&nbsp;where X is the Vendée Globe ID.
              </li>
              <Tooltip
                multiline
                place="left"
                text="List of Vendee Globe IDs:<br>1 = CORUM L'Epargne. 2 = L'Occitane en Provence. 4 = Arkea-Paprec<br>7 = La Fabrique. 8 = Charal. 9 = OMIA - Water Family<br>10 = Seaexplorer - Yacth Club De Monaco. 14 = La Mie Caline - Artisans Artipole<br>17 = Maitre Coq IV. 18 = Bureau Vallee 2. 28 = MACSF<br>30 = Banque Populaire X. 33 = One Planet One Ocean. 34 = Prysmian Group<br>37 = Campagne de France. 49 = Pure - Best Western Hotels and Resorts<br>53 = V And B Mayenne. 56 = Newrest - Art et Fenetres. 59 = LinkedOut<br>69 = Merci. 71 =  Groupe Setin. 72 = TSE - 4myplanet<br>79 = APIVIA. 83 = Compagnie du lit - Jiliti. 85 = PRB<br>92 = Time For Oceans. 99 = Hugo Boss. 109 = Initiatives - Coeur<br>222 = Stark. 777 = Medallia. 1000 = Groupe APICIL<br>1001 = Yes we Cam!. 1101 = DMG MORI Global One"
              />
            </TooltipListContainer>
          </ul>
        </InnerContent>
        <Title>Version 0.1.5</Title>
        <InnerContent>
          Brushing up on our final release ahead of Vendée Globe, focusing on
          <b> better integration with Adrena</b>
          <span>, and</span>
          <b> more routing options</b>
          , including routing meteograms and routing tables.
          <br />
          <ul>
            <li>
              Integrating with Adrena for importing sail configuration tables
              into Dorado, and exporting Dorado routing output to Adrena in CSV
              format
            </li>
            <li>Detailed routing tables and meteograms along the route</li>
            <li>
              Displaying meteogram from already downloaded data (right click on
              the area covered with downloaded meteo visualizations, Show
              meteogram)
            </li>
            <li>
              Added Exclusion zones to the map and taking them into account for
              routings
            </li>
            <li>
              Displaying popup windows when hovering the cursor over routing
              points and areas with meteo data displayed
            </li>
          </ul>
        </InnerContent>
        <Title>Version 0.1</Title>
        <InnerContent>
          Our first alpha release ahead of Vendée Globe focusing on two core
          functionalities - viewing and analyzing
          <b> meteo forecasts </b>
          and
          <b> creating routings </b>
          on ECMWF and GFS models. The app has been created with full support of
          <b> offline mode</b>
          , giving users control over all downloads and data transfers.
          <br />
          <ul>
            <li>
              Downloading and displaying meteo data visualizations for 4 models
              - ECMWF, GFS, ECMWF WAM waves, Mercator Ocean NEMO currents
            </li>
            <li>
              Detailed meteograms for 10 days ahead in any point (right click on
              the map - Show full meteogram)
            </li>
            <li>Routing on ECMWF and GFS models, isochrones</li>
            <li>
              Download functionality, full control over all data transfers
            </li>
            <li>User profile and accounts, uploading boat polars</li>
          </ul>
        </InnerContent>
      </Content>
      <Footer>
        {hasUpdate && (
          <Button clickHandler={updateServiceWorker} label="Update" />
        )}
        <TitleText>
          {`v${VersionString} ${
            process.env.REACT_APP_BUILD_NUMBER
              ? `(${process.env.REACT_APP_BUILD_NUMBER})`
              : ''
          }`}
        </TitleText>
      </Footer>
    </>
  );
};

export default React.memo(WhatsNewModal);
