import styled from 'styled-components';

import { Colors } from 'modules/ui/theme/colors';
import { RegularSmCss, RegularXs, H2 } from 'modules/ui/typography';

export const Content = styled.div`
  padding: 0 16px 16px 16px;
  overflow-y: auto;
`;

export const InnerContent = styled.div`
  ${RegularSmCss};
  margin-bottom: 8px;
`;
export const Title = styled(H2)`
  background-color: ${Colors.LightGray};
  padding: 8px 0;
`;

export const Footer = styled.div`
  height: 52px;
  min-height: 52px;
  background-color: ${Colors.LightGray};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  gap: 16px;
`;

export const TitleText = styled(RegularXs)`
  color: ${Colors.SolidGray};
`;

export const TooltipListContainer = styled.div`
  display: flex;
`;
